import './App.css';
import Header from './components/header/Header'
import Dashboard from './pages/Dashboard/Dashboard';
import {BrowserRouter as Router,Routes,Route} from 'react-router-dom'
import Products from './pages/products/Products';
import AddProduct from './pages/addProduct/AddProduct';
import Login from './pages/login/Login';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { PublicRoute } from "./routing/PublicRoute";
import { PrivateRoute } from "./routing/PrivateRoute";
import Orders from './pages/orders/Orders';
import SingleOrder from './pages/orders/SingleOrder'
// import CreateOrder from './pages/orders/CreateOrder';
// import EditOrder from './pages/orders/EditOrder'
import CouponList from './pages/coupon/CouponList';
import Coupon from './pages/coupon/Coupon'
import UpdateCoupon from './pages/coupon/UpdateCoupon';
import CollectionList from './pages/collection/CollectionList';
import Collection from './pages/collection/Collection';
// import CustomerList from './pages/customers/CustomerList';
// import SingleCustomer from './pages/customers/SingleCustomer';
import SingleBlog from './pages/blogs/SingleBlog';
import Blog from './pages/blogs/Blog';
// import { useLocation, useNavigate } from 'react-router-dom';
import React from 'react';
// import Abandoneds from './pages/abandoned/Abandoned';
// import SingleAbandoned from './pages/abandoned/SingleAbandoned';
// import OldOrder from './pages/oldOders/oldOrder';
// import History from './pages/history/History';
import Banner from './pages/banners/Banner'
import BannerList from './pages/banners/bannerList'

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}
function AppContent() {


  return (
    <DndProvider backend={HTML5Backend} basename="/">
      <>
        <Header/>
        <Routes>
          <Route path='/' element={<PrivateRoute><Dashboard/></PrivateRoute>}/>
          <Route path='/login' element={<PublicRoute><Login/></PublicRoute>}/>
          <Route path='/products' element={<Products/>}/>
          {/* <Route path='/history' element={<History/>}/> */}

          {/* <Route path='/addProduct' element={<AddProduct/>}/> */}
          <Route path='/addProduct/:id' element={<AddProduct/>}/>
          <Route path='/orders' element={<Orders/>}/>
          {/* <Route path='/abandoneds' element={<Abandoneds/>}/> */}
          <Route path='/orders/:id' element={<SingleOrder/>}/>
          {/* <Route path='/abandoneds/:id' element={<SingleAbandoned/>}/> */}
          {/* <Route path='/orders/neworder' element={<CreateOrder/>}/> */}
          {/* <Route path='/editOrder/:id' element={<EditOrder/>}/> */}
          <Route path="/coupon" element={<CouponList/>}/>
          <Route path="/coupon/new" element={<Coupon/>}/>
          <Route path="/coupon/:id" element={<UpdateCoupon/>}/>
          <Route path="/collection" element={<CollectionList/>}/>
          <Route path="/collections" element={<Collection/>}/>
          <Route path="/collection/:id" element={<Collection/>}/>
          <Route path="/banner" element={<BannerList/>}/>
          <Route path="/banner-new" element={<Banner/>}/>
          <Route path="/banner/:id" element={<Banner/>}/>
          <Route path='/blogs' element={<Blog/>}/>
          <Route path='/singleblog' element={<SingleBlog/>}/>
          <Route path='/singleblog/:id' element={<SingleBlog/>}/>
        </Routes>
      </>
    </DndProvider>
  );
}


export default App;
