import React, { useState,useEffect } from 'react'
import ReactQuill from "react-quill";
import './blog.css'
import Dropzone from "react-dropzone";
import { delImg, uploadImg } from "../../features/upload/uploadSlice";
import { useDispatch, useSelector } from "react-redux";
import {useLocation, useNavigate} from 'react-router-dom'
import { createBlogs,getABlog ,resetState, updateABlog,deleteABlog} from '../../features/blogs/blogSlice';
import { toast } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';

const SingleBlog = () => {
  const user=JSON.parse(localStorage.getItem("user"))

  const [title,setTitle]=useState("")
  const [desc,setDesc]=useState("")
  const [metaTitle,setMetaTitle]=useState("")
  const [metaDesc,setMetaDesc]=useState("")
  const [handle,setHandle]=useState("")
  const [images,setImages]=useState([])
  const [author,setAuthor]=useState("")
  const [state,setState]=useState("draft")
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      ['clean']
    ],
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]
  const location = useLocation()
  const getBlogId = location.pathname.split("/")[2];
  const dispatch=useDispatch()
  const navigate=useNavigate()
  const imgState = useSelector((state) => state.upload.images);
  const blogState=useSelector((state)=>state?.blogs?.singleBlog)
  useEffect(()=>{
    setTitle(blogState?.title)
      setDesc(blogState?.description)
      setMetaTitle(blogState?.metaTitle)
      setMetaDesc(blogState?.metaDesc)
      setAuthor(blogState?.author)
      setState(blogState?.state)
      setHandle(blogState?.handle)
  },[blogState])
  useEffect(() => {

    if (getBlogId !== undefined) {
      dispatch(getABlog(getBlogId));
    } else {
      dispatch(resetState());
    }
  }, [getBlogId]);

useEffect(()=>{
    setImages(imgState)
},[imgState])
  const img = [];
  imgState.forEach((i) => {
    img.push({
      public_id: i.public_id,
      url: i.url,
    });
  });


const createBlog=()=>{
  if(title==="" || desc==="" || author==="" || handle===""){
    toast.info("Please Fill All The Required Fields")
  }
  
   else {
    if (getBlogId !== undefined) {
      dispatch(updateABlog({id:getBlogId, blogData:{
        title:title,
        description:desc,
        handle:handle,
        metaTitle:metaTitle,
        metaDesc:metaDesc,
        state:state,
        author:author,
        images:images,
        createdAt:blogState?.createdAt,
      }
      }))

    }

   else{
    dispatch(createBlogs({title:title,
      description:desc,
      handle:handle,
      metaTitle:metaTitle,
      metaDesc:metaDesc,
      state:state,
      author:author,
      images:images,
    }))

   }
  }
}
const deleteImg=()=>{
  dispatch(delImg(blogState?.images[0]?.public_id))
  setImages([])
}
const deleteBlog=()=>{
  dispatch(deleteABlog(getBlogId))
  setTimeout(()=>{
navigate("/blogs")
  },1000)
}

  return (
    <div className='blogs'>
      <div className="blogs-head">
        <p><span></span><span></span></p>
        <div className="right">
            <button onClick={deleteBlog}>Delete</button>
            <button onClick={createBlog}>Save</button>
        </div>
      </div>
      <div className="blog-data">
        <div className="blog-left">
            <div className="left1">
            <div className="blog-title">
              <p>Title</p>
              <input type="text" placeholder='Enter Title' onChange={(e)=>setTitle(e.target.value)} value={title}/>

</div>
<div className="blog-desc">
  <p>Description</p>
  <ReactQuill
                  className='descr'
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  placeholder='Enter Description' onChange={setDesc} value={desc}
                />

</div>
<div className="blog-handle">
<p>Handle</p>
              <input type="text" placeholder='Enter Url' onChange={(e)=>setHandle(e.target.value)} value={handle}/>
</div>

            </div>
            <div className="left2">
    <div className="metaTitle">
    <p>Meta Title</p>
              <input type="text" placeholder='Enter Meta Title' onChange={(e)=>setMetaTitle(e.target.value)} value={metaTitle}/>
    </div>
    <div className="metaDesc">
    <p>Meta Description</p>
              <textarea placeholder='Enter Meta Description' onChange={(e)=>setMetaDesc(e.target.value)} value={metaDesc}></textarea>
    </div>
</div>
        </div>
        <div className="blog-right">
            <div className="state">
                <p>Status</p>
                <select name="" id="" onChange={(e)=>setState(e.target.value)} value={state}>
                  <option value="draft">Draft</option>
                  <option value="active">Active</option>
                </select>
            </div>
            <div className="image">
              <p>Image</p>
              {
                images?.length===0?
                <Dropzone
                onDrop={(acceptedFiles) => dispatch(uploadImg(acceptedFiles))}
                style={{width:'100px',height:'100x',border:'1px solid grey',borderRadius:'5px'}}
               >
                {({ getRootProps, getInputProps }) => (
                  <section >
                    <div {...getRootProps()} className="img-box-in">
                      <input {...getInputProps()} />
                      <p>Drag 'n' drop some files here, or click to select files</p>
                    </div>
                  </section>
                )}
              </Dropzone>
              :
              <div>
                <img src={images && images[0]?.url} alt={title} />
<p className="delete" onClick={deleteImg}><CloseIcon/></p>
              </div>

              }
              
           
            </div>
            <div className="author">
            <p>Author</p>
              <input type="text" placeholder='Enter Author Name' onChange={(e)=>setAuthor(e.target.value)} value={author}/>
            </div>
            <div className="numview">
            <p>Views</p>
              <p style={{fontWeight:600,fontSize:'18px'}}>30</p>
            </div>
            <div className="createdAt">
            <p>Created On</p>
            <p style={{fontWeight:600,fontSize:'18px'}}>10/10/1011</p>
              {/* <p style={{fontWeight:600,fontSize:'18px'}}>{new Date(item?.createdAt).toLocaleDateString('en-GB')}</p> */}
            </div>

        </div>
      </div>
    </div>
  )
}

export default SingleBlog
