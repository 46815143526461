import React,{useEffect,useState} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'

import {createCoupon} from '../../features/coupon/couponSlice'
const Coupon = () => {
  const user=JSON.parse(localStorage.getItem("user"))

  const [discountName,setDiscountName]=useState("")
  const [discountType,setDiscountType]=useState("order")
  const [expiry,setExpiry]=useState("")
  const [customerType,setCustomerType]=useState("all")
  const [discountAmount,setDiscountAmount]=useState("null")
  const [status,setStatus]=useState("draft")
  const [minItem,setMinItem]=useState(0)
  const [customerEmail,setCustomerEmail]=useState("")
  const [minNone,setMinNone]=useState("none")
  const [mailNone,setMailNone]=useState("none")
  const [priceNone,setPriceNone]=useState("block")
  const newCoupon = useSelector((state) => state?.coupon);

  const {
    isSuccess,
    isError,
    isLoading,
    createdCoupon,
  } = newCoupon;


  const dispatch=useDispatch()
  const navigate=useNavigate()
   useEffect(()=>{
    if(discountType==="buyX"){
      setMinNone("block")
      setPriceNone("block")
    }
    if(discountType==="freeShip"){
      setMinNone("none")
      setPriceNone("none")
    }
    if(discountType==="order"){
      setMinNone("none")
      setPriceNone("block")
    }

   },[discountType])
   useEffect(()=>{
    if(customerType==="all"){
      setMailNone("none")
    }
    if(customerType==="specific"){
      setMailNone("block")
    }

   },[customerType])
const createNewDiscount=()=>{
  if(discountName==="" || expiry===""){
    toast.error("Please Fill All the Details")
  }
  if(discountType==="buyX"){
    if(minItem===""){
      toast.error("Please Fill All the Details")
    }
  }
  if(customerType==="specific"){
    if(customerEmail===""){
      toast.error("Please Fill All the Details")
    }
  }
  else{

      dispatch(createCoupon({
        name:discountName,
        expiry:expiry,
        discount:discountAmount,
        discounttype:discountType,
        customertype:customerType,
        status:status,
        minItem:minItem,
        cEmail:customerEmail,
      }))

    
    
  }
}

  return (
    <div className='coupon'>
      <div className="coupon-head">
        <p>Create Coupon</p>
        <div className="right">
        <button onClick={createNewDiscount}>Save</button>
        </div>

      </div>
      <div className="coupon-make">
          <div className="center-div">
          <div className="coupon-type">
            <p>Select Coupon Type</p>
            <select name="" id="" value={discountType} onChange={(e)=>setDiscountType(e.target.value)}>
              <option value="order">Order Discount</option>
              <option value="buyX">Buy X Get Y</option>
              <option value="freeShip">Free Shipping</option>
            </select>
          </div>
          <div className="name" style={{display:minNone}}>
            <p>Define X Value</p>
            <input type="number" placeholder='Min Items / Min Amount' value={minItem} onChange={(e)=>setMinItem(e.target.value)}/>
          </div>
          <div className="name">
            <p>Coupon Name</p>
            <input type="text" placeholder='Name' value={discountName} onChange={(e)=>setDiscountName(e.target.value)}/>
          </div>
          <div className="expiry">
            <p>Select Coupon Expiry</p>
            <input type="date" name="" id="" value={expiry} onChange={(e)=>setExpiry(e.target.value)}/>
          </div>
          <div className="coupon-status">
            <p>What will be the status of this Coupon?</p>
            <select name="" id="" value={status} onChange={(e)=>setStatus(e.target.value)}>
              <option value="draft">Draft</option>
              <option value="active">Active</option>
            </select>
          </div>
          <div className="price" style={{display:priceNone}}>
            <p>Please Input Discount Amount</p>
            <p className='alert'>For percent enter like 10%, for amount like 1999</p>
            <input type="text" placeholder='10% or 2499' value={discountAmount} onChange={(e)=>setDiscountAmount(e.target.value)}/>
          </div>
          <div className="ctype">
            <p>To whom do you want to give this coupon?</p>
            <select name="" id="" value={customerType} onChange={(e)=>setCustomerType(e.target.value)}>
              <option value="all">All Customers</option>
              <option value="specific">Specific Customer</option>
            </select>
          </div>
          <div className="cmail" style={{display:mailNone}}>
            <p>Please Enter Customer Email</p>
            <input type="email" name="" id="" placeholder='xyz@gmail.com' value={customerEmail} onChange={(e)=>setCustomerEmail(e.target.value)}/>
          </div>
          </div>
      </div>
    </div>
  )
}

export default Coupon
