import React, { useEffect } from 'react'
import './banner.css'
import {useDispatch,useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import { getBanners } from '../../features/banner/bannerSlice'
const BannerList = () => {
    const dispatch=useDispatch()
    const navigate=useNavigate()

    
    const bannerState = useSelector((state) => state?.banner?.banners);
    useEffect(()=>{
        dispatch(getBanners())
    },[dispatch])
console.log(bannerState)
  return (
    <div className='banner'>
      <div className="banner-head">
        <p>Banners</p>
        <button onClick={()=>navigate("/banner-new")}>Add banner</button>
      </div>
      <div className="banner-list">
        {
            bannerState?.map((item,index)=>{
                return(
                    <Link to={`/banner/${item?._id}`}>
                        <div className="banner-card">
                            <div className="left">
                                <img src={item?.images && item?.images[0]?.url} alt="" />
                            </div>
                            <div className="right">
                            <p className='name'>{item?.title}</p>
                            </div>
                        </div>
                    </Link>
                )
            })
        }
      </div>
    </div>
  )
}

export default BannerList
