import React, { useState,useEffect } from 'react'
import ReactQuill from "react-quill";
import './banner.css'
import Dropzone from "react-dropzone";
import { delImg, uploadImg } from "../../features/upload/uploadSlice";
import { useDispatch, useSelector } from "react-redux";
import {useLocation, useNavigate} from 'react-router-dom'
import { createBanner ,getABanner,resetState, updateABanner,deleteABanner} from '../../features/banner/bannerSlice';
import { toast } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';

const Banner = () => {
  const user=JSON.parse(localStorage.getItem("user"))

  const [title,setTitle]=useState("")
  const [alt,setAlt]=useState("")

  const [images,setImages]=useState([])
  const location = useLocation()
  const getBannerId = location.pathname.split("/")[2];
  const dispatch=useDispatch()
  const navigate=useNavigate()
  const imgState = useSelector((state) => state.upload.images);
  const bannerState=useSelector((state)=>state?.banner?.singleBanner)
  console.log(bannerState)
  useEffect(()=>{
    setTitle(bannerState?.title)     
      setImages(bannerState?.images)
      setAlt(bannerState?.alt)


  },[bannerState])
  useEffect(() => {

    if (getBannerId !== undefined) {
      dispatch(getABanner(getBannerId));
    } else {
      dispatch(resetState());
    }
  }, [getBannerId]);

useEffect(()=>{
    setImages(imgState)
},[imgState])
  const img = [];
  imgState.forEach((i) => {
    img.push({
      public_id: i.public_id,
      url: i.url,
    });
  });


const createABanner=()=>{
  if(title===""){
    toast.info("Please Fill All The Required Fields")
  }
  
   else {
    if (getBannerId !== undefined) {
      dispatch(updateABanner({id:getBannerId, data:{
        title:title,
        images:images,
        alt:alt,
        createdAt:bannerState?.createdAt,
      }
      }))

    }

   else{
    dispatch(createBanner({title:title,
      alt:alt,
      images:images,
    }))

   }
  }
}
const deleteImg=()=>{
  dispatch(delImg(bannerState?.images[0]?.public_id))
  setImages([])
}
const deletebanner=()=>{
  dispatch(deleteABanner(getBannerId))
  setTimeout(()=>{
navigate("/banner")
  },1000)
}
  return (
    <div className='banner'>
        <div className="banner-head">
            <p>Create banner</p>
            <div className="right">
            <button onClick={deletebanner}>Delete</button>
            <button onClick={createABanner}>Save</button>
        </div>
        </div>
      <div className="banner-page">
      <div className="category">
        <p>Name</p>
        <input type="text" placeholder='Enter Name' onChange={(e)=>setTitle(e.target.value)} value={title}/>

        </div>
        
        <div className="image">
        <p>Image</p>
              {
                images?.length===0?
                <Dropzone
                onDrop={(acceptedFiles) => dispatch(uploadImg(acceptedFiles))}
                style={{width:'100px',height:'100x',border:'1px solid grey',borderRadius:'5px'}}
               >
                {({ getRootProps, getInputProps }) => (
                  <section >
                    <div {...getRootProps()} className="img-box-in">
                      <input {...getInputProps()} />
                      <p>Drag 'n' drop some files here, or click to select files</p>
                    </div>
                  </section>
                )}
              </Dropzone>
              :
              <div>
                <img src={images && images[0]?.url} alt={title} />
<p className="delete" onClick={deleteImg}><CloseIcon/></p>
              </div>

              }
              
           
        </div>
        <div className="category">
        <p>alt</p>
        <input type="text" placeholder='Enter alt' onChange={(e)=>setAlt(e.target.value)} value={alt}/>

        </div>
       
        
      </div>
    </div>
  )
}

export default Banner
