import React, { useEffect } from 'react'
import './collection.css'
import {useDispatch,useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import { getCollections } from '../../features/collection/collectionSlice'
const CollectionList = () => {
    const dispatch=useDispatch()
    const navigate=useNavigate()

    
    const collectionState = useSelector((state) => state?.collection?.collections);
    useEffect(()=>{
        dispatch(getCollections())
    },[dispatch])

  return (
    <div className='collection'>
      <div className="collection-head">
        <p>Collections</p>
        <button onClick={()=>navigate("/collections")}>Add Collection</button>
      </div>
      <div className="collection-list">
        {
            collectionState?.map((item,index)=>{
                return(
                    <Link to={`/collection/${item?._id}`}>
                        <div className="collection-card">
                            <div className="left">
                                <img src={item?.images && item?.images[0]?.url} alt="" />
                            </div>
                            <div className="right">
                            <p className='name'>{item?.category}</p>
                            <p>{item?.products?.length}</p>
                            <p className='status'>{item?.status}</p>
                            </div>
                        </div>
                    </Link>
                )
            })
        }
      </div>
    </div>
  )
}

export default CollectionList
