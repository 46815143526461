import { React, useEffect, useState,useRef } from "react";
import './addproduct.css'
import { useDrag, useDrop } from "react-dnd";
import ReactQuill from "react-quill";
import {useLocation, useParams,useNavigate } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close';
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { createProducts, resetState, getAProduct, updateAProduct, getProducts,deleteAProduct, getAllProducts} from "../../features/product/productSlice";
import { getCollections } from "../../features/collection/collectionSlice";
let schema = yup.object().shape({
  title: yup.string().required("Title is Required"),
  handle: yup.string().required("handle is Required"),
  description: yup.string().required("Description is Required"),
  price: yup.number().required("Price is Required"),
  category: yup.string().required("Category is Required"),
  brand: yup.string().required("Brand is Required"),
  sku: yup.string().required("SKU is Required"),
  collectionName: yup.string().required("Collection is Required"),
  variants: yup.array().of(
    yup.object().shape({
      color: yup.string(),
      size: yup.string(),
      quantity: yup.number().required('Variant quantity is required')
    })
  ).required('At least one variant is required'),
});



const AddProduct = () => {
  const user=JSON.parse(localStorage.getItem("user"))
  const [main,setMain]=useState("")
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      ['clean']
    ],
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]


  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation()
  useEffect(() => {
    dispatch(getCollections());
  }, []);
  useEffect(() => {
    dispatch(getProducts({limit:3000,page:1}));
  }, []);
  const getProductId = location.pathname.split("/")[2];
  const imgState = useSelector((state) => state.upload.images);
  const collectionState = useSelector((state) => state.collection.collections);
  const productStat = useSelector((state) => state?.product)

  const {
    productTitle,
    productDescription,
    productCategory,
    productImages,
    productPrice,
    productHandle,
    productBrand,
    productSku,
    productState,
    productCollectionName,
    productVariants,
    productTags,
    metaDesc,
    metaTitle,
    metaDesc1,
    metaTitle1,
      } = productStat
  useEffect(() => {

    if (getProductId !== undefined) {
      dispatch(getAProduct(getProductId));
      img.push(productImages);
    } else {
      dispatch(resetState());
    }
  }, [getProductId]);




  const img = [];
  imgState.forEach((i) => {
    img.push({
      public_id: i.public_id,
      url: i.url,
    });
  });
  useEffect(() => {
    formik.values.images = img;
  }, [productImages]);


  const combinedImages = productImages?.length > 0 ? [...productImages, ...imgState] : imgState;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: productTitle || "",
      tags: productTags || "",
      state: productState || "draft",
      handle: productHandle || "",
      description: productDescription || "",
      price: productPrice || '',
      category: productCategory || '',
      brand: productBrand || '',
      sku: productSku || '',
      images:combinedImages || imgState,
      collectionName: productCollectionName || '',
      variants: productVariants || [],
      metaDesc:metaDesc||"",
      metaTitle:metaTitle||"",
      metaDesc1:metaDesc1||"",
      metaTitle1:metaTitle1||""
    },

    validationSchema: schema,
    
  });

  useEffect(() => {
    setVariants(productVariants || []);
  }, [productVariants]);

 

  const [variants, setVariants] = useState(productVariants || []);



  const productState1 = useSelector((state) => state?.product?.prdt);

  const [productList, setProductList] = useState([]);

  useEffect(() => {
    dispatch(getAllProducts({limit:100,page:1}))

    
}, [dispatch]);
useEffect(()=>{
        // Assuming the payload.data contains array of product details
        const productIds = productState1?.map(product => product?._id);
        setProductList(productIds);
    
},[productState1])
const { id } = useParams(); // Assuming 'id' is the param name for product ID in the route
const currentProductIndex = productList?.indexOf(id);

const prevProductId = currentProductIndex > 0 ? productList[currentProductIndex - 1] : null;
const nextProductId = currentProductIndex < productList?.length - 1 ? productList[currentProductIndex + 1] : null;
const handlePrevious = () => {
  if (prevProductId) {
      navigate(`/addProduct/${prevProductId}`);
  }
};

const handleNext = () => {
  if (nextProductId) {
      navigate(`/addProduct/${nextProductId}`);
  }
};
  return (
    <div className='singlep'>
      <div className="main-image" style={{display:main!==""?"block":'none'}}>
        <img src={main} alt="" />
        <p className="close" onClick={()=>setMain("")}><CloseIcon/></p>
      </div>
      <div className="back d-flex my-4 align-items-center">
        {/* <Link to="/products"><IoMdArrowRoundBack style={{ color: 'black', marginRight: '10px', fontSize: '20px' }} /></Link> */}
        <div className="del">
        <p style={{ fontWeight: 500, fontSize: '22px' }}>{formik.values.title}</p>
        <div className="buttons">
    {prevProductId && (
        <button onClick={handlePrevious} style={{padding:"0"}}><ChevronLeftIcon/></button>
    )}
    {nextProductId && (
        <button onClick={handleNext} style={{padding:"0"}}><NavigateNextIcon /></button>
    )}
        </div>
        </div>
      </div>
        <div className="mains">
          <div className="left">
            <div className="basic">

              <div className="title">
                <p>Title</p>
                <input type="text"
                  placeholder={formik.values.title}
                  name="title"
                  onBlur={formik.handleBlur("title")}
                  value={formik.values.title} />
              </div>
              <div className="error">
                {formik.touched.title && formik.errors.title}
              </div>
              <div className="title my-2">
                <p>Handle</p>
                <input type="text"
                  placeholder="Enter Product Handle"
                  name="handle"
                  onBlur={formik.handleBlur("handle")}
                  value={formik.values.handle} />
              </div>
              <div className="error">
                {formik.touched.handle && formik.errors.handle}
              </div>
              <div className="title my-2">
                <p>Price</p>
                <input
                  type="number"
                  placeholder="Enter Product Price"
                  name="price"
                  onBlur={formik.handleBlur("price")}
                  value={formik.values.price} />
              </div>
              <div className="error">
                {formik.touched.price && formik.errors.price}
              </div>
              <div className="desc">
                <p>Description</p>
                <ReactQuill
                  className='descr'
                  theme="snow"
                  name="description"
                  modules={modules}
                  formats={formats}
                  value={formik.values.description}
                />
              </div>
              <div className="error">
                {formik.touched.description && formik.errors.description}
              </div>

            </div>




            <div className="bg-white border-1 p-5 text-center">

            </div>
            <div className="showimages d-flex flex-wrap gap-3">

            </div>



            <div className="media">
              <p>Media</p>
              <div className="imgbox">
    {formik.values.images.map((image, index) => (
      <img
        key={image?.public_id}
        src={image?.url}
        id={image?.public_id}
        index={index}
      />
      
    ))}
  </div>

            </div>
            <div className="variants">
              <p>Variants</p>
              {variants.map((variant, index) => (
          <div className="variant" key={index}>
            <input
              type="text"
              value={variant.color}
              placeholder="Color"
            />
            <input
              type="text"
              value={variant.size}
              placeholder="Size"
            />
            <input
              type="number"
              value={variant.quantity}
              placeholder="Quantity"
            />
           
          </div>
        ))}
      
            </div>
          </div>
          <div className="right">
            <div className="status">
              <p>Status</p>
              <select
              defaultValue={formik.values.state}
                name="state"
                onBlur={formik.handleBlur("state")}
                value={formik.values.state}
                className="form-control py-3 mb-3"
                id="">
                <option value="active">Active</option>
                <option value="draft">Draft</option>
              </select>
            </div>
            <div className="insights">
              <div>
                <p>Insights</p>
                <p>Last 30 days</p>
              </div>
              <p>30 items sold</p>
            </div>
            <div className="p-organization">
              <p>Product Organization</p>
              <div className="title">
                <p>Product Category</p>
                <input type="text"
                  name="category"
                  onBlur={formik.handleBlur("category")}
                  value={formik.values.category}
                  className="form-control py-3 mb-3"
                  id="" />
              </div>
              <div className="title">
                <p>Brand</p>
                <input type="text"
                  placeholder="Enter Product Brand"
                  name="brand"
                  onBlur={formik.handleBlur("brand")}
                  value={formik.values.brand} />
              </div>
              <div className="title">
                <p>SKU</p>
                <input type="text"
                  placeholder="Enter Product SKU"
                  name="sku"
                  onBlur={formik.handleBlur("sku")}
                  value={formik.values.sku} />
              </div>
              <div className="title">
                <p>Collection</p>
                <select
                  name="collectionName"
                  onBlur={formik.handleBlur("collectionName")}
                  value={formik.values.collectionName}
                  className="form-control py-3 mb-3"
                  id=""
                >
                  <option value="">Select Collection</option>
                  {collectionState.map((i, j) => {
                    return (
                      <option key={j} value={i.title}>
                        {i.title}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="title">
                <p>Tags</p>
                <input type="text"
                  placeholder="Enter Product Tags"
                  name="tags"
                  onBlur={formik.handleBlur("tags")}
                  value={formik.values.tags} />
              </div>
              
            </div>
          </div>
        </div>
        <div className="submit">
        </div>

      
    </div>
  )
}

export default AddProduct