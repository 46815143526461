import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";

const getProducts = async (data) => {
  
  const response = await axios.get(`${base_url}product?limit=${data.limit}&page=${data.page}`);

  return response.data;
};
const getAllProducts = async (data) => {
  const response = await axios.get(`${base_url}product?limit=100&page=${data.page}${data.sort && `&sort=${data.sort}`}${data.state && `&state=${data.state}`}`);

  return response.data;
};
const createProduct = async (product) => {
  const response = await axios.post(`${base_url}product/`, product, config);

  return response.data;
};


const updateProduct = async (product) => {
  const response = await axios.put(
    `${base_url}product/${product.id}`,
    {
      title: product.productData.title,
      description: product.productData.description,
      category: product.productData.category,
      images: product.productData.images,
      price:product.productData.price,
      handle:product.productData.handle,
      brand:product.productData.brand,
      sku:product.productData.sku,
      state:product.productData.state,
      collectionName:product.productData.collectionName,
      variants:product.productData.variants,
      tags:product.productData.tags,
      metaTitle:product.productData.metaTitle,
      metaDesc:product.productData.metaDesc,
      metaTitle1:product.productData.metaTitle1,
      metaDesc1:product.productData.metaDesc1
    },
    config
  );

  return response.data;
};
const getProduct = async (id) => {
  const response = await axios.get(`${base_url}product/dashboard/${id}`, config);

  return response.data;
};

const deleteProduct = async (id) => {
  const response = await axios.delete(`${base_url}product/${id}`, config);

  return response.data;
};

const productService = {
  getProducts,
  createProduct,
  deleteProduct,
  updateProduct,
  getProduct,
  getAllProducts
};

export default productService;
